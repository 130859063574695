.testimonial13-max-width {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.testimonial13-section-title {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.testimonial13-container {
  gap: var(--dl-space-space-twounits);
  width: auto;
}
.testimonial13-content1 {
  gap: var(--dl-space-space-twounits);
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.testimonial13-stars1 {
  fill: var(--dl-color-theme-primary1);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.testimonial13-avatar1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.testimonial13-avatar-image1 {
  width: 56px;
  height: 56px;
  object-fit: cover;
}
.testimonial13-avatar-content1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.testimonial13-logo1 {
  height: 2rem;
}
.testimonial13-content2 {
  gap: var(--dl-space-space-twounits);
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.testimonial13-stars2 {
  fill: var(--dl-color-theme-primary1);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.testimonial13-avatar2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.testimonial13-avatar-image2 {
  width: 56px;
  height: 56px;
  object-fit: cover;
}
.testimonial13-avatar-content2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.testimonial13-logo2 {
  width: 68px;
  height: 52px;
}
.testimonial13-text16 {
  color: #ffffff;
  display: inline-block;
  text-align: justify;
}
.testimonial13-text25 {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.testimonial13-text26 {
  font-size: 14px;
  font-weight: 700;
}
.testimonial13-text28 {
  color: #ffffff;
  display: inline-block;
}
.testimonial13-text29 {
  fill: var(--dl-color-theme-neutral-dark);
  color: #ffffff;
  display: inline-block;
  text-align: justify;
}
.testimonial13-text48 {
  color: rgb(255, 255, 255);
  display: inline-block;
}
.testimonial13-text49 {
  color: #ffffff;
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.testimonial13-text50 {
  color: #ffffff;
  display: inline-block;
}
.testimonial13-text51 {
  color: #fffefe;
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.testimonial13-text52 {
  color: #ffffff;
  display: inline-block;
}
@media(max-width: 991px) {
  .testimonial13-container {
    align-items: center;
  }
}
@media(max-width: 767px) {
  .testimonial13-max-width {
    padding: var(--dl-space-space-threeunits);
  }
  .testimonial13-section-title {
    width: 100%;
    max-width: 800px;
  }
  .testimonial13-container {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .testimonial13-max-width {
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .testimonial13-avatar1 {
    flex-direction: column;
  }
  .testimonial13-avatar-content1 {
    align-items: center;
    justify-content: center;
  }
  .testimonial13-avatar2 {
    flex-direction: column;
  }
  .testimonial13-avatar-content2 {
    align-items: center;
    justify-content: center;
  }
}
