.contact-form3-contact9 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.contact-form3-container {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.contact-form3-video {
  width: 530px;
  height: 540px;
}
.contact-form3-content {
  gap: 0;
  flex: 1;
  align-items: stretch;
}
.contact-form3-section-title {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form3-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form3-text {
  color: rgb(255, 255, 255);
  font-size: 60px;
  max-width: 900px;
  font-style: normal;
  font-weight: 400;
}
.contact-form3-text1 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}
.contact-form3-input {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  flex-direction: column;
}
.contact-form3-text2 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}
.contact-form3-text-input {
  width: 419px;
  height: 54px;
}
.contact-form3-input1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  flex-direction: column;
}
.contact-form3-text3 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}
.contact-form3-text-input1 {
  width: 406px;
  height: 52px;
}
.contact-form3-container1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.contact-form3-text4 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}
.contact-form3-checkbox {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
}
.contact-form3-button {
  width: 305px;
  text-decoration: none;
}
.contact-form3-container3 {
  display: contents;
}
@media(max-width: 991px) {
  .contact-form3-max-width {
    flex-direction: column;
  }
  .contact-form3-content {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .contact-form3-text {
    font-size: 40px;
  }
  .contact-form3-text1 {
    font-size: 16px;
    line-height: 24px;
  }
  .contact-form3-text2 {
    font-size: 16px;
    line-height: 24px;
  }
  .contact-form3-text3 {
    font-size: 16px;
    line-height: 24px;
  }
  .contact-form3-text4 {
    font-size: 16px;
    line-height: 24px;
  }
}
@media(max-width: 479px) {
  .contact-form3-text {
    font-size: 36px;
    line-height: 42px;
  }
  .contact-form3-button {
    text-align: center;
  }
}
