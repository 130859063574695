.gallery5-gallery3 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.gallery5-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.gallery5-section-title {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.gallery5-text {
  color: rgb(255, 255, 255);
  width: 1060px;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.gallery5-text1 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 600px;
  text-align: justify;
  line-height: 36px;
}
.gallery5-container {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.gallery5-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.gallery5-container1 {
  gap: HalfUnits;
  flex: 1;
  width: 100%;
  height: 902px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.gallery5-container2 {
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
}
.gallery5-link {
  display: contents;
}
.gallery5-image1 {
  width: 556px;
  height: 377px;
  max-width: 100%;
  align-self: center;
  max-height: 700px;
  text-decoration: none;
}
.gallery5-link1 {
  display: contents;
}
.gallery5-image2 {
  width: 100%;
  height: 60%;
  max-height: 700px;
  object-fit: cover;
  text-decoration: none;
}
.gallery5-container3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.gallery5-link2 {
  display: contents;
}
.gallery5-image3 {
  width: 541px;
  height: 440px;
  max-height: 700px;
  object-fit: cover;
  text-decoration: none;
}
.gallery5-link3 {
  display: contents;
}
.gallery5-image4 {
  width: 100%;
  height: 440px;
  max-height: 700px;
  object-fit: cover;
  text-decoration: none;
}
@media(max-width: 991px) {
  .gallery5-content {
    align-items: center;
    flex-direction: column;
  }
  .gallery5-container1 {
    height: auto;
  }
  .gallery5-image1 {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
  }
  .gallery5-image2 {
    height: 100%;
  }
  .gallery5-container3 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .gallery5-section-title {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .gallery5-text {
    font-size: 40px;
  }
  .gallery5-text1 {
    font-size: 16px;
    line-height: 24px;
  }
}
@media(max-width: 479px) {
  .gallery5-text {
    width: 454px;
    font-size: 36px;
  }
  .gallery5-image2 {
    max-height: 400px;
  }
}
