.home-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #000000;
}
.home-hero {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-video {
  top: 0px;
  left: 0px;
  width: 100%;
  filter: opacity(0.6);
  height: 100%;
  position: absolute;
  object-fit: cover;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
}
.home-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}
.home-branding {
  width: 120px;
  object-fit: cover;
}
.home-burger-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-hamburger {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: #0000F5;
}
.home-hamburger:hover {
  opacity: .5;
}
.home-icon {
  width: 60px;
  object-fit: cover;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-black);
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-image {
  width: 76px;
  height: 80px;
}
.home-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon01 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link01 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link02 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link03 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link04 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link05 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-icon-group {
  fill: var(--dl-color-gray-white);
  display: flex;
}
.home-icon03 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon05 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon07 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-hero-content {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header-container {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 846px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading {
  color: rgb(255, 255, 255);
  font-size: 100px;
  max-width: 790px;
  font-style: normal;
  font-weight: 500;
}
.home-caption {
  color: rgb(255, 255, 255);
  width: 1200px;
  font-size: 24px;
  max-width: 800px;
  text-align: left;
  line-height: 36px;
}
.home-link06 {
  text-decoration: none;
}
.home-note {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: flex-start;
}
.home-caption01 {
  color: rgb(124, 124, 128);
  width: 1111px;
  height: 258px;
  font-size: 38px;
  max-width: auto;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  line-height: 50px;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 0px;
}
.home-statistics {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-content {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.home-stat {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-right-width: 1px;
}
.home-header01 {
  color: rgb(255, 255, 255);
  font-size: 62px;
  font-style: italic;
  text-align: center;
  font-family: "Playfair Display";
  font-weight: 500;
  line-height: 73px;
}
.home-caption02 {
  color: rgb(124, 124, 128);
}
.home-stat1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-right-width: 1px;
}
.home-header02 {
  color: rgb(255, 255, 255);
  font-size: 62px;
  font-style: italic;
  text-align: center;
  font-family: "Playfair Display";
  font-weight: 500;
  line-height: 73px;
}
.home-caption03 {
  color: rgb(124, 124, 128);
}
.home-stat2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-header03 {
  color: rgb(255, 255, 255);
  font-size: 62px;
  font-style: italic;
  text-align: center;
  font-family: "Playfair Display";
  font-weight: 500;
  line-height: 73px;
}
.home-caption04 {
  color: rgb(124, 124, 128);
}
.home-slider {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}
.home-header04 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading01 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  max-width: 900px;
  font-style: normal;
  font-weight: 400;
}
.home-text10 {
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 500;
}
.home-selector {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.home-caption05 {
  color: rgb(124, 124, 128);
  width: 573px;
  height: 201px;
  font-size: 24px;
  max-width: 600px;
  text-align: justify;
  line-height: 34px;
}
.home-slide-titles {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-slides {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-slider1 {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-slider-container {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-slider-track-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-slider-track {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-image01 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-content01 {
  width: 378px;
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-header05 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-heading02 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
.home-caption06 {
  color: rgb(204, 204, 255);
  width: 286px;
  max-width: 600px;
  text-align: justify;
  line-height: 24px;
}
.home-more {
  gap: 4px;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-more:hover {
  opacity: 0.5;
}
.home-caption07 {
  color: rgb(255, 255, 255);
  max-width: 600px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
}
.home-icon09 {
  fill: #ffffff;
  width: 14px;
  height: 14px;
}
.home-image02 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-content02 {
  width: 378px;
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-header06 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-heading03 {
  color: rgb(255, 255, 255);
}
.home-caption08 {
  color: rgb(204, 204, 255);
  width: 286px;
  max-width: 600px;
  text-align: justify;
  line-height: 24px;
}
.home-more1 {
  gap: 4px;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-more1:hover {
  opacity: 0.5;
}
.home-caption09 {
  color: rgb(255, 255, 255);
  max-width: 600px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
}
.home-icon11 {
  fill: #ffffff;
  width: 14px;
  height: 14px;
}
.home-image03 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-content03 {
  width: 378px;
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-header07 {
  gap: var(--dl-space-space-threeunits);
  width: 264px;
  display: flex;
  flex-direction: column;
}
.home-heading04 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
.home-caption10 {
  color: rgb(204, 204, 255);
  width: 286px;
  max-width: 600px;
  text-align: justify;
  line-height: 24px;
}
.home-more2 {
  gap: 4px;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-more2:hover {
  opacity: 0.5;
}
.home-caption11 {
  color: rgb(255, 255, 255);
  max-width: 600px;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-weight: 500;
  text-decoration: none;
}
.home-icon13 {
  fill: #ffffff;
  width: 14px;
  height: 14px;
}
.home-image04 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-content04 {
  width: 378px;
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-header08 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-heading05 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
.home-caption12 {
  color: rgb(204, 204, 255);
  width: 286px;
  max-width: 600px;
  text-align: justify;
  line-height: 24px;
}
.home-more3 {
  gap: 4px;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-more3:hover {
  opacity: 0.5;
}
.home-caption13 {
  color: rgb(255, 255, 255);
  max-width: 600px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
}
.home-icon15 {
  fill: #ffffff;
  width: 14px;
  height: 14px;
}
.home-controls {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-previous {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
}
.home-icon17 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.home-next {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
}
.home-icon19 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.home-container02 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container04 {
  display: contents;
}
.home-container06 {
  display: contents;
}
.home-get-started {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.home-header-container1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  z-index: 100;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-header09 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading06 {
  color: rgb(255, 255, 255);
  width: 1060px;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-text17 {
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 400;
}
.home-caption14 {
  color: rgb(124, 124, 128);
  font-size: 24px;
  max-width: 600px;
  text-align: justify;
  line-height: 36px;
}
.home-button {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.home-button1 {
  text-decoration: none;
}
.home-image05 {
  top: 0px;
  right: 0px;
  width: 720px;
  bottom: 0px;
  margin: auto;
  position: absolute;
  object-fit: cover;
}
.home-objectives {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-content05 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
  border-top-width: 1px;
}
.home-text19 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-objectives-list {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: flex-start;
}
.home-text20 {
  color: rgb(255, 255, 255);
}
.home-text21 {
  color: rgb(124, 124, 128);
  width: 284px;
  text-align: justify;
}
.home-text22 {
  color: rgb(255, 255, 255);
}
.home-text23 {
  color: rgb(124, 124, 128);
  text-align: justify;
}
.home-text24 {
  color: rgb(255, 255, 255);
}
.home-text25 {
  color: rgb(124, 124, 128);
  text-align: justify;
}
.home-growth {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.home-content06 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header10 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-right: 190px;
  flex-direction: column;
  justify-content: center;
}
.home-header11 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading07 {
  color: rgb(255, 255, 255);
  width: 1060px;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-text26 {
  font-weight: 400;
}
.home-text27 {
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 500;
}
.home-text28 {
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 500;
}
.home-text29 {
  font-style: normal;
  font-weight: 400;
}
.home-caption15 {
  color: rgb(124, 124, 128);
  width: 532px;
  font-size: 24px;
  max-width: 560px;
  text-align: justify;
  line-height: 36px;
}
.home-caption16 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  text-align: justify;
  line-height: 36px;
  border-color: #313133;
  padding-bottom: var(--dl-space-space-threeunits);
  border-bottom-width: 1px;
}
.home-images {
  flex: 1;
  display: flex;
  position: relative;
  align-items: flex-end;
  padding-top: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-square {
  top: 64px;
  right: -35px;
  width: 180px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  aspect-ratio: 1;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #0000FF;
}
.home-image06 {
  width: 640px;
  height: 434px;
  z-index: 100;
  margin-top: var(--dl-space-space-sixunits);
  object-fit: cover;
}
.home-experience {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-images1 {
  flex: 1;
  display: flex;
  position: relative;
  align-items: flex-end;
  padding-top: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-square1 {
  left: -35px;
  width: 180px;
  bottom: -35px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  aspect-ratio: 1;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #0000FF;
}
.home-video1 {
  width: 640px;
  height: 434px;
  z-index: 100;
  margin-top: var(--dl-space-space-sixunits);
  object-fit: cover;
}
.home-content07 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header12 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  border-bottom-width: 1px;
}
.home-header-container2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header13 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading08 {
  color: rgb(255, 255, 255);
  width: 1060px;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-text32 {
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 500;
}
.home-caption17 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  line-height: 36px;
}
.home-checkmarks {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-check {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-mark {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #0000F5;
}
.home-icon21 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-text34 {
  color: rgb(255, 255, 255);
}
.home-check1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-mark1 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #0000F5;
}
.home-icon23 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-text35 {
  color: rgb(255, 255, 255);
}
.home-check2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-mark2 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #0000F5;
}
.home-icon25 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-text36 {
  color: rgb(255, 255, 255);
}
.home-check3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-mark3 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #0000F5;
}
.home-icon27 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-text37 {
  color: rgb(255, 255, 255);
}
.home-data {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-header-container3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header14 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-row {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-heading09 {
  color: rgb(255, 255, 255);
  width: 1060px;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.home-text39 {
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 500;
}
.home-text40 {
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 500;
}
.home-text41 {
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 500;
}
.home-text42 {
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 500;
}
.home-caption18 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 600px;
  line-height: 36px;
}
.home-content08 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-image07 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image08 {
  width: 440px;
  object-fit: cover;
}
.home-accordion {
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.home-header15 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
.home-header16 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
.home-header17 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
.home-header18 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
.home-container08 {
  display: contents;
}
.home-create {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-content17 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  background-color: #0000F5;
}
.home-header19 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-heading10 {
  color: rgb(255, 255, 255);
  font-size: 100px;
  text-align: center;
  font-weight: 500;
}
.home-text43 {
  font-style: normal;
}
.home-text44 {
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 500;
}
.home-text45 {
  font-style: normal;
  font-weight: 400;
}
.home-text46 {
  font-weight: 500;
}
.home-text47 {
  font-style: normal;
}
.home-text48 {
  font-style: normal;
}
.home-link07 {
  text-decoration: none;
  background-color: #000000;
}
.home-customer {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-header20 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  flex-direction: column;
}
.home-heading11 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  max-width: 900px;
  font-style: normal;
  font-weight: 400;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.home-text52 {
  font-family: ""Playfair Display"";
}
.home-caption19 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 600px;
  text-align: justify;
  line-height: 36px;
  margin-left: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-unit);
}
.home-container10 {
  display: contents;
}
.home-faq {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.home-header21 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-heading12 {
  color: rgb(255, 255, 255);
}
.home-content18 {
  gap: 120px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-column {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-element4 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-header22 {
  color: rgb(255, 255, 255);
}
.home-content19 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-element5 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-header23 {
  color: rgb(255, 255, 255);
}
.home-content20 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-element6 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-header24 {
  color: rgb(255, 255, 255);
}
.home-content21 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-column1 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-element7 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-header25 {
  color: rgb(255, 255, 255);
}
.home-content22 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-element8 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-header26 {
  color: rgb(255, 255, 255);
}
.home-content23 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-content24 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  border-top-width: 1px;
}
.home-main {
  gap: 240px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-branding1 {
  gap: var(--dl-space-space-twounits);
  width: 400px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image09 {
  width: 120px;
  object-fit: cover;
}
.home-text59 {
  color: rgb(124, 124, 128);
}
.home-links {
  gap: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-column2 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header27 {
  font-size: 24px;
}
.home-list {
  width: 155px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link08 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link08:hover {
  opacity: .5;
}
.home-link09 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link09:hover {
  opacity: .5;
}
.home-link10 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link10:hover {
  opacity: .5;
}
.home-link11 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link11:hover {
  opacity: .5;
}
.home-text65:hover {
  opacity: .5;
}
.home-column3 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  width: 106px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header28 {
  font-size: 24px;
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.home-list1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link12 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link12:hover {
  opacity: .5;
}
.home-link13 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link13:hover {
  opacity: .5;
}
.home-link14 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link14:hover {
  opacity: .5;
}
.home-link15 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link15:hover {
  opacity: .5;
}
.home-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text67 {
  color: rgb(124, 124, 128);
}
.home-button2 {
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 0px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
}
.home-image10 {
  width: 21px;
  object-fit: cover;
}
.home-container12 {
  display: contents;
}
@media(max-width: 991px) {
  .home-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
  .home-hero-content {
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-content {
    padding-top: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-stat {
    width: 100%;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-stat1 {
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-stat2 {
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-selector {
    gap: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-slider1 {
    gap: var(--dl-space-space-twounits);
  }
  .home-image01 {
    width: 100%;
    height: 120px;
  }
  .home-content01 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-header05 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-heading02 {
    font-size: 24px;
  }
  .home-caption06 {
    max-width: 600px;
  }
  .home-image02 {
    width: 100%;
    height: 120px;
  }
  .home-content02 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-header06 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-heading03 {
    font-size: 24px;
  }
  .home-caption08 {
    max-width: 600px;
  }
  .home-image03 {
    width: 100%;
    height: 120px;
  }
  .home-content03 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-header07 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-heading04 {
    font-size: 24px;
  }
  .home-caption10 {
    max-width: 600px;
  }
  .home-image04 {
    width: 100%;
    height: 120px;
  }
  .home-content04 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-header08 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-heading05 {
    font-size: 24px;
  }
  .home-caption12 {
    max-width: 600px;
  }
  .home-heading06 {
    width: 899px;
  }
  .home-caption14 {
    width: 693px;
  }
  .home-content05 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-objectives-list {
    padding-right: 0px;
  }
  .home-objective1 {
    flex: 1;
  }
  .home-objective2 {
    flex: 1;
  }
  .home-growth {
    flex-direction: column;
  }
  .home-images {
    padding-top: 0px;
  }
  .home-square {
    top: -20px;
    right: -20px;
  }
  .home-image06 {
    margin-top: 0px;
  }
  .home-experience {
    align-items: flex-start;
    flex-direction: column-reverse;
  }
  .home-images1 {
    padding-top: 0px;
  }
  .home-video1 {
    margin-top: 0px;
  }
  .home-content08 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-header15 {
    font-size: 24px;
  }
  .home-header16 {
    font-size: 24px;
  }
  .home-header17 {
    font-size: 24px;
  }
  .home-header18 {
    font-size: 24px;
  }
  .home-content17 {
    gap: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading10 {
    font-size: 50px;
  }
  .home-customer {
    max-width: 1400px;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
  }
  .home-header20 {
    max-width: 100%;
  }
  .home-heading11 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-faq {
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-content18 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-column {
    max-width: 100%;
  }
  .home-header22 {
    font-size: 24px;
  }
  .home-content19 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-header23 {
    font-size: 24px;
  }
  .home-content20 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-header24 {
    font-size: 24px;
  }
  .home-content21 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-column1 {
    max-width: 100%;
  }
  .home-header25 {
    font-size: 24px;
  }
  .home-content22 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-header26 {
    font-size: 24px;
  }
  .home-content23 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-content24 {
    border-top-width: 0px;
  }
  .home-main {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-branding1 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .home-burger-menu {
    display: flex;
  }
  .home-header-container {
    width: 740px;
  }
  .home-heading {
    font-size: 60px;
  }
  .home-caption {
    width: 666px;
  }
  .home-note {
    align-items: flex-start;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-caption01 {
    font-size: 18px;
    max-width: 90%;
    line-height: 23px;
    margin-right: 0px;
  }
  .home-statistics {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-stat {
    gap: var(--dl-space-space-unit);
  }
  .home-header01 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-caption02 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-stat1 {
    gap: var(--dl-space-space-unit);
  }
  .home-header02 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-caption03 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-stat2 {
    gap: var(--dl-space-space-unit);
  }
  .home-header03 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-caption04 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-slider {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-heading01 {
    font-size: 40px;
  }
  .home-caption05 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-slide-titles {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-slides {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-slider1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-slider-track {
    align-items: stretch;
  }
  .home-heading02 {
    font-size: 18px;
  }
  .home-caption06 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-caption07 {
    font-size: 14px;
  }
  .home-icon09 {
    width: 11px;
    height: 11px;
  }
  .home-heading03 {
    font-size: 18px;
  }
  .home-caption08 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-caption09 {
    font-size: 14px;
  }
  .home-icon11 {
    width: 11px;
    height: 11px;
  }
  .home-heading04 {
    font-size: 18px;
  }
  .home-caption10 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-caption11 {
    font-size: 14px;
  }
  .home-icon13 {
    width: 11px;
    height: 11px;
  }
  .home-heading05 {
    font-size: 18px;
  }
  .home-caption12 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-caption13 {
    font-size: 14px;
  }
  .home-icon15 {
    width: 11px;
    height: 11px;
  }
  .home-previous {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-icon17 {
    width: 14px;
    height: 14px;
  }
  .home-next {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-icon19 {
    width: 14px;
    height: 14px;
  }
  .home-get-started {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-header09 {
    width: 733px;
  }
  .home-heading06 {
    width: 723px;
    font-size: 40px;
  }
  .home-caption14 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-image05 {
    width: 300px;
  }
  .home-objectives {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-content05 {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-objectives-list {
    width: 100%;
    flex-direction: column;
  }
  .home-growth {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-content06 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-header10 {
    gap: var(--dl-space-space-unit);
  }
  .home-heading07 {
    font-size: 40px;
  }
  .home-caption15 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-caption16 {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-image06 {
    width: 100%;
  }
  .home-experience {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-square1 {
    left: -20px;
    width: 20%;
    bottom: -20px;
  }
  .home-video1 {
    width: 100%;
  }
  .home-content07 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-header12 {
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-header-container2 {
    gap: var(--dl-space-space-unit);
  }
  .home-heading08 {
    font-size: 40px;
  }
  .home-caption17 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-data {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-heading09 {
    font-size: 40px;
  }
  .home-caption18 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-image07 {
    width: 100%;
  }
  .home-image08 {
    width: 100%;
  }
  .home-create {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-content17 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading10 {
    font-size: 36px;
  }
  .home-customer {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-heading11 {
    color: rgb(255, 255, 255);
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-text52 {
    font-family: Playfair Display;
  }
  .home-caption19 {
    font-size: 16px;
    text-align: justify;
    line-height: 24px;
  }
  .home-faq {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-header21 {
    gap: var(--dl-space-space-unit);
    align-items: center;
  }
  .home-heading12 {
    font-size: 40px;
  }
  .home-column {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-element4 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-header22 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-element5 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-header23 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-element6 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-header24 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-column1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-element7 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-header25 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-element8 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-header26 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-footer {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-links {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: row;
  }
}
@media(max-width: 479px) {
  .home-branding {
    padding-top: var(--dl-space-space-unit);
  }
  .home-hamburger {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-icon {
    width: 30px;
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-hero-content {
    gap: var(--dl-space-space-threeunits);
  }
  .home-header-container {
    width: 446px;
  }
  .home-heading {
    font-size: 36px;
  }
  .home-caption {
    width: 422px;
    font-size: 16px;
    line-height: 24px;
  }
  .home-link06 {
    text-align: center;
  }
  .home-slider {
    max-width: 100%;
  }
  .home-heading01 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-caption05 {
    width: 418px;
  }
  .home-slide-titles {
    display: none;
  }
  .home-image01 {
    height: 180px;
  }
  .home-content01 {
    height: 165px;
  }
  .home-image02 {
    height: 180px;
  }
  .home-image03 {
    height: 170px;
  }
  .home-slide3 {
    height: 345px;
  }
  .home-image04 {
    height: 50%;
  }
  .home-header-container1 {
    width: 478px;
  }
  .home-heading06 {
    width: 454px;
    font-size: 36px;
  }
  .home-caption14 {
    width: 416px;
    height: 173px;
  }
  .home-image05 {
    height: 400px;
  }
  .home-text19 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text20 {
    font-size: 18px;
  }
  .home-text22 {
    font-size: 18px;
  }
  .home-text24 {
    font-size: 18px;
  }
  .home-text25 {
    color: rgb(124, 124, 128);
  }
  .home-header10 {
    width: 460px;
    margin-right: 0px;
  }
  .home-heading07 {
    width: 454px;
    font-size: 36px;
  }
  .home-caption15 {
    width: 418px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-caption16 {
    width: 418px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-heading08 {
    width: 454px;
    font-size: 36px;
  }
  .home-heading09 {
    width: 454px;
    font-size: 36px;
  }
  .home-heading10 {
    color: rgb(255, 255, 255);
    font-style: normal;
    text-align: center;
    font-weight: 500;
  }
  .home-link07 {
    text-align: center;
  }
  .home-heading11 {
    color: rgb(255, 255, 255);
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
  }
  .home-text52 {
    font-style: italic;
    font-family: Playfair Display;
    font-weight: 500;
  }
  .home-caption19 {
    text-align: justify;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .home-header21 {
    gap: 0px;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-heading12 {
    color: rgb(255, 255, 255);
    text-align: center;
  }
  .home-links {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-bottom {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
  .home-button2 {
    justify-content: center;
  }
}
